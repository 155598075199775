/** @jsxImportSource @emotion/react */

import {createHashRouter, RouterProvider, Navigate, useSearchParams} from 'react-router-dom';
import Tools from 'script/sweet-tools.mjs'
import LoginPage from 'pages/LoginPage/LoginPage';
import MainPage from 'pages/MainPage/MainPage.jsx';
import ModifyPassword from "pages/ModifyPassword/ModifyPassword";

const Router = () => {

    /**
     * 正式用户登录校验拦截
     * @param children
     * @returns {JSX.Element|*}
     * @constructor
     */
    const RequireAuth = ({children}) => {
        if (Tools.authenticator.verify()) {
            const user = Tools.authenticator.getUser()
            if (user.initPwdFlag) {
                return <Navigate to="/modifyPassword" replace/>
            } else {
                // useDOM.restoreTitle();
                return children;
            }
        } else {
            return <Navigate to="/login" replace/>;
        }
    }

    const router = createHashRouter([
        {
            path: '/',
            element: <RequireAuth><MainPage/></RequireAuth>,
        },
        {
            path: '/login',
            element: <LoginPage/>,
        },

        {
            path: '/modifyPassword',
            element: <ModifyPassword/>,
        },
        {
            path: '*',
            element: <Navigate to="/" replace/>,
        },
    ])

    return <RouterProvider router={router}/>
}

export default Router

import useGlobalData from "../hooks/useGlobalData.mjs";
import {nanoid} from "nanoid";
import Tools from "./sweet-tools.mjs";

let cache = null

const authenticator = () => {
}

authenticator.checkStrong = (value) => {
    var pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z]{1})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\(\)]).*$/
    return pattern.test(value);
}

authenticator.strongName = (level) => {
    switch (level) {
        case 1:
            return '弱' //强度为1
        case 2:
            return '一般' //强度为2
        case 3: //强度为3
            return '中'
        case 4: //强度为4
            return '强'
    }
}

authenticator.get = () => {
    if (! cache) {
        const s = localStorage.getItem(process.env.REACT_APP_SESSION)

        if (s) {
            cache = JSON.parse(s)
        }
    }

    return cache
}

authenticator.set = (values) => {
    cache = values

    if (values) {
        window.localStorage.setItem(process.env.REACT_APP_SESSION, JSON.stringify(values));
    } else {
        window.localStorage.removeItem(process.env.REACT_APP_SESSION)
    }
}

authenticator.clear = () => {
    cache = null
    window.localStorage.removeItem(process.env.REACT_APP_SESSION)
}

authenticator.getUser = () => {
    const authObj = authenticator.get();
    return authObj?.user;
}

authenticator.getTokenInfo = () => {
    const authObj = authenticator.get();
    return authObj;
}

authenticator.getToken = () => {
    const authObj = authenticator.get();
    return authObj?.token;
}

authenticator.verify = () => {
    const authObj = authenticator.get();
    if (authObj) {
        const {token, tokenExpires, refreshToken, refreshTokenExpires} = authObj;
        if (tokenExpires > new Date().getTime()) {
            return true;
        }
    }
    return false;
}

authenticator.verifyRefreshToken = () => {
    const authObj = authenticator.get();
    if (authObj) {
        const {token, tokenExpires, refreshToken, refreshTokenExpires} = authObj;
        if (refreshTokenExpires > new Date().getTime()) {
            return true;
        }
    }
    return false;
}

/**
 * 是否具有权限
 * 判断"菜单权限码_操作权限码"是否存在于用户的权限集合中
 * @param menuCode 菜单权限码
 * @param btnCode 操作权限码
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermission = ({menuCode, btnCode}) => {
    let hasPermission = false;
    if (menuCode && btnCode) {
        const user = authenticator.getUser();
        const isAdmin = user.userIsAdmin;
        //如果是管理员，默认为有权限
        if (isAdmin) {
            console.log("isAdmin", isAdmin)
            return true;
        }

        const permissionMap = user.permissionMap;
        const permissionKey = menuCode + "_" + btnCode;
        const permission = permissionMap[permissionKey];
        if (permission) {
            hasPermission = true;
        }
    }
    return hasPermission;
}

/**
 * 是否没有对应权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDenied = (params) => {
    return !authenticator.hasPermission(params);
}


export default authenticator;

/** @jsxImportSource @emotion/react */
import 'antd/dist/antd.css';
import RootPage from "./pages/RootPage/RootPage";
import RouterPage from "./pages/RouterPage/RouterPage";
import DraggableManager from 'components/Draggable/DraggableManager.jsx'
import ErrorBoundary from 'components/ErrorBoundry/ErrorBoundry.jsx'
import ModalManager from 'components/Modal/ModalManager.jsx'
import Spin from 'components/Spin/Spin.jsx'
import AntdConfig from './AntdConfig.jsx'

window.global = window.global ?? window.globalThis

const App = () => {



    return (
        <ErrorBoundary>
            <AntdConfig>
                <RootPage>
                    <DraggableManager>
                        <ModalManager>
                            <Spin>
                                <RouterPage></RouterPage>
                            </Spin>
                        </ModalManager>
                    </DraggableManager>
                </RootPage>
            </AntdConfig>
        </ErrorBoundary>
    );
}

export default App;

import {Modal} from 'antd'
import {CustomError} from 'hopedove-dom'
import {join} from 'hopedove-dom/url'
import deepEqual from 'script/deepEqual.mjs'
import authenticator from './sweet-authenticator.mjs'

export class NetworkError extends CustomError {
    constructor(status, statusText) {
        super(`${status}: ${statusText}`)
        this.status = status
    }
}

export class ServerError extends CustomError {
    constructor(code, message) {
        super(`${message}`)
        this.code = code
    }
}

const fetch = async (resource, init) => {
    const response = await window.fetch(resource, init)
    if (! response.ok) {
        const {status, statusText} = response
        throw new NetworkError(status, statusText)
    }
    const contentType = response.headers.get('Content-Type')
    if (/json/.test(contentType)) {

const jsonBody = await response.json()
        const {code, errorBody, message, responseBody} = jsonBody

        if ('200' !== code && '00000000' !== code) {
            const errMsg = errorBody?.errorMessage ?? message
            const errorCode = errorBody?.errorCode ?? code
            throw new ServerError(errorCode, errMsg)
        }

        return responseBody
    }
    else {
        const blob = await response.blob()

        const fileName = (() => {
            const cd = response.headers.get('Content-Disposition')

            if (cd) {
                const match = cd.match(/filename=(\S+)/)

                if (match) {
                    return window.decodeURIComponent(match[1])
                }
            }

            return ''
        })()

        return {blob, fileName}
    }
}

const requestPool = (() => {
    const pool = new Map()

    return {
        delete: (promise) => {
            pool.delete(promise)
        },

        get: (request) => {
            for (const [promise, req] of pool.entries()) {
                if (deepEqual(req, request)) {
                    return promise
                }
            }
        },

        set: (request, promise) => {
            pool.set(promise, request)
        },
    }
})()

const doRequest = ({headers, method, payload, url}) => {
    let resource = url ?
        new window.URL(
            url.startsWith('/') ?
                join(process.env.REACT_APP_HTTP_BASE_URL, url)
                :
                url
        )
        :
        process.env.REACT_APP_HTTP_BASE_URL

    const init = {
        headers: {
            'Accept': 'application/json;charset=UTF-8',
            'Content-Type': 'application/json;charset=UTF-8',
            'COM_APPLICATION_ID': process.env.REACT_APP_APPLICATION_ID,
            ...headers
        },

        method,
    }

    const token = authenticator.getToken()

    if (token) {
        init.headers.__TOKEN__ = token
    }

    if ('GET' === method || 'HEAD' === method) {
        for (const [key, value] of Object.entries(payload)) {
            if (undefined !== value && null !== value) {
                if (value instanceof Array) {
                    for (let v of value) {
                        resource.searchParams.append(key, v);
                    }
                } else {
                    resource.searchParams.set(key, value);
                }
            }
        }
    } else if ('UPLOAD' === method) {
        resource = new window.URL(join(process.env.REACT_APP_HTTP_BASE_URL, '/files'));
        delete init.headers['Content-Type'];
        // console.log('文件上传需要排除 Content-Type')

        init.method = "POST"

        const body = new FormData();

        for (const [key, value] of Object.entries(payload)) {
            body.append(key, value);
        }
        init.body = body;
    } else if ('POSTFILE' === method) {
        delete init.headers['Content-Type'];
        // console.log('文件上传需要排除 Content-Type')

        init.method = "POST"

        const body = new FormData();

        for (const [key, value] of Object.entries(payload)) {
            if (Array.isArray(value)) {
                for (const e of value) {
                    body.append(key, e)
                }
            } else {
                body.append(key, value);
            }
        }
        init.body = body;
    } else {
        init.body = JSON.stringify(payload);
    }

    return fetch(resource, init)
}

let isRefreshingToken = false
let refreshTokenPromise = Promise.resolve()

const refreshToken = async () => {
    const {token, refreshToken} = authenticator.get() ?? {}

    try {
        isRefreshingToken = true

        const session = await doRequest({
            method: 'POST',
            payload: {token, refreshToken},
            url: '/refreshToken',
        })

        authenticator.set(session)
    }
    catch (err) {
        Modal.error({
            content: '登录信息已过期，请重新登录',
            afterClose: () => location.hash = '#/login',
        })
    }
    finally {
        isRefreshingToken = false
    }
}

const request = async ({
    headers = {},
    method,
    payload = {},
    url,
}) => {
    const tuple = {headers, method, payload, url}
    const reusablePromise = requestPool.get(tuple)

    if (reusablePromise) {
        return reusablePromise
    }

    if (isRefreshingToken) {
        await refreshTokenPromise
    }

    const promise = (async () => {
        try {
            return await doRequest(tuple)
        }
        catch (err) {
            if ('TOKEN_VERIFY_FAIL' === err.code || '00000010' === err.code) {
                if (! isRefreshingToken) {
                    refreshTokenPromise = refreshToken()
                }

                await refreshTokenPromise
                return doRequest(tuple)
            }
            else {
                throw err
            }
        }
        finally {
            requestPool.delete(promise)
        }
    })()

    requestPool.set(tuple, promise)
    return promise
}

const http = {
    request,

    ...Object.fromEntries(
        ['delete', 'get', 'post', 'put', 'postFile'].map(
            (methodName) => [
                methodName,

                (url, payload = {}, options = {}) => {
                    return request({
                        ...options,
                        method: methodName.toUpperCase(),
                        payload,
                        url,
                    })
                },
            ]
        )
    ),
    upload: (payload = {}, options = {}) => {
        return request({
            ...options,
            method: 'UPLOAD',
            payload,
        })
    }
};

export default http;

import Tools from 'script/sweet-tools.mjs'

//缓存 Key 集合
const Keys = () => {
}

Keys.TableConfig = 'TABLE_CONFIG';
//字典哈希,适用于获取某个字典的字典值数组,key=dictCode,
Keys.HASH_DICT = 'HASH_DICT';
//字典值哈希,适用于获取某个字典下某个字典值对应的数据,key=dictCode_dictItemCode,
Keys.HASH_DICT_DTL = 'HASH_DICT_DTL';
//用户数组
Keys.LIST_USER = 'LIST_USER';
//用户哈希,key=orgId
Keys.HASH_USER = 'HASH_USER'
//组织数组
Keys.LIST_ORG = 'LIST_ORG';
//组织哈希,key=orgId
Keys.HASH_ORG = 'HASH_ORG'
//Tab最多打开个数Hash
Keys.HASH_TAB_MAX = 'HASH_TAB_MAX';
//公共文件哈希,key=cfCode
Keys.HASH_COMMON_FILE = 'HASH_COMMON_FILE';//公共文件

export {Keys};

//缓存结构体
const globalMap = new Map();
const DataSource = () => {
    return globalMap;
};
export {DataSource};

//加载字典
const loadDict = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/listEnabledDict`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            const dictMap = new Map();
            const dictDtlMap = new Map();
            data.forEach(({dictCode, dictItems = []}) => {
                dictMap.set(dictCode, dictItems);

                if (dictItems) {
                    dictItems.forEach(item => {
                        dictDtlMap.set(dictCode + "-" + item.dictItemCode, item);
                    })
                }
            })
            DataSource().set(Keys.HASH_DICT, dictMap);
            DataSource().set(Keys.HASH_DICT_DTL, dictDtlMap);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载表格配置
const loadTableConfig = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/sysTableConfig/listSysTableConfigByUser`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            const map = new Map();
            data.forEach(({tcId, tableCode, userId, tcContent, ...props}) => {
                const key = tableCode
                map.set(key, JSON.parse(tcContent));
            })
            DataSource().set(Keys.TableConfig, map);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载公共文件列表
const loadCommonFile = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/sysCommonFile/listSysCommonFile`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const map = new Map();
        if (data) {
            data.forEach(({cfId, cfCode, fileId, ...values}) => {
                map.set(cfCode, {
                    ...values,
                    cfId,
                    cfCode,
                    fileId,
                    url: Tools.convertToUrl(fileId),
                });
            })
            console.log("加载公共文件", data);
        }
        DataSource().set(Keys.HASH_COMMON_FILE, map);
    } catch (e) {
        console.error(e)
    }
}

//加载用户
const loadUser = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/listUser`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const userMap = new Map();
        if (data) {
            data.forEach(({userId, ...props}) => {
                userMap.set(userId, {userId, ...props});
            })
            DataSource().set(Keys.HASH_USER, userMap);
        }
        DataSource().set(Keys.LIST_USER, data ?? []);

    } catch (e) {
        console.error(e)
    }
}

//加载组织
const loadOrg = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/listOrg`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        DataSource().set(Keys.LIST_ORG, data);
        const orgMap = new Map();
        data.forEach(item => {
            orgMap.set(item.orgId, item);
        })
        DataSource().set(Keys.HASH_ORG, orgMap);
    } catch (e) {
        console.error(e)
    }
}

//加载tab最多打开个数
const loadTabMax = async () => {
    try {
        DataSource().set(Keys.HASH_TAB_MAX, 15);
    } catch (e) {
        console.error(e)
    }
}

/**
 * 获取全局数据
 * @returns {Promise<void>}
 */
const useGlobalData = {

    /**
     * 初始化前端缓存
     * @returns {Promise<void>}
     */
    init: async () => {
        if (DataSource().size == 0) {
            const promises = [
                loadDict(),
                loadUser(),
                loadOrg(),
                loadTabMax()
            ]
            await Promise.all(promises)
        }
    },

    refreshOrg: async () => {
        await loadOrg();
    },

    /**
     * 获取指定字典编码的字典值数组
     * @param dictCode
     */
    getDictDtls: (dictCode) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            return data.get(dictCode);
        }
        return [];
    },

    /**
     * 获取指定字典编码、字典值编码的字典值对象
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtl: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key);
            }
        }
        return null;
    },

    /**
     * 获取指定字典编码、字典值编码的中文名称
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtlCn: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key).dictItemNameCn ?? '';
            }
        }
        return '';
    },

    /**
     * 获取指定字典编码、字典值编码的英文名称
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtlEn: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key).dictItemNameEn ?? '';
            }
        }
        return '';
    },

    /**
     * 获取表格配置
     * @param tableCode
     * @param userId
     * @param custId
     * @returns {*}
     */
    getTableConfig: ({tableCode}) => {
        const data = DataSource().get(Keys.TableConfig);
        if (data) {
            const value = data.get(tableCode);
            return value ?? null;
        } else {
            return null;
        }

    },

    /**
     * 刷新表格配置
     * @returns {Promise<void>}
     */
    refreshTableConfig: async () => {
        await loadTableConfig();
    },

    /**
     * 获取选项配置列表
     * @param optSceneCode_ 选项配置场景CODE
     * @param optDataTypeCode_ 选项配置数据类型CODE
     * @returns {*[]|*}
     */
    getOpts: (optSceneCode_, optDataTypeCode_) => {
        const data = DataSource().get(Keys.OPT);
        if (data) {
            return data.filter(({optSceneCode, optDataTypeCode}) => optSceneCode_ == optSceneCode && optDataTypeCode_ == optDataTypeCode)
        } else {
            return []
        }
    },

    /**
     * 获取公共文件
     * @param cfCode 文件编码
     */
    getCommonFile: (cfCode) => {
        const map = DataSource().get(Keys.HASH_COMMON_FILE);
        if (map) {
            return map.get(cfCode);
        } else {
            return {}
        }
    },

    /**
     * 获取员工数组
     * @param dictCode
     */
    getUsers: () => {
        const data = DataSource().get(Keys.LIST_USER);
        if (data) {
            return [...data];
        }
        return [];
    },

    getUser: (userId) => {
        return DataSource().get(Keys.HASH_USER).get(userId)
    },

    getOrgs: () => {
        const data = DataSource().get(Keys.LIST_ORG);
        if (data) {
            return [...data];
        }
        return [];
    },

    getOrg: (orgId) => {
        const data = DataSource().get(Keys.HASH_ORG);
        return data.get(orgId);
    },

    getOrgPathNo: (keys) => {
        const data = DataSource().get(Keys.HASH_ORG);
        if (Array.isArray(keys)) {
            const result = [];
            for (let key of keys) {
                if (data && data.has(key)) {
                    result.push(data.get(key).orgPathNo)
                }
            }
            return result;
        } else {
            if (data && data.has(keys)) {
                return data.get(keys).orgPathNo;
            }
        }
        return null;
    },


    getTabMax: () => {
        return DataSource().get(Keys.HASH_TAB_MAX);
    },
}


export default useGlobalData;

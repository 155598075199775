/** @jsxImportSource @emotion/react */

import {Fragment, useEffect, useState} from 'react'
import {Spin} from 'antd'
import {DashboardOutlined} from '@ant-design/icons'
import MainTabs, {MainTabsProvider, useMainTabsContext} from 'components/MainTabs/MainTabs.jsx'
import MainHeader from './MainHeader.jsx'
import useGlobalData from 'hooks/useGlobalData.mjs'
import Tools from "../../script/sweet-tools.mjs";

const MainPageContent = () => {
    
    const user = Tools.authenticator.getUser();
    
    const mainTabs = useMainTabsContext()
    

    useEffect(() => {
        init();
        }, []
    )
    
    const init =async () => {
        mainTabs.openTab({
            closable: false,
            component: ['Workbench/Workbench'],
            name: <span><DashboardOutlined/> 工作台</span>,
        })
        
        if (user.firstMenu) {
            mainTabs.openTab({
                name: user.firstMenu.menuName,
                component: [user.firstMenu.uri],
            })
        }
    
    }
    
    return (
        <Fragment>
            <MainHeader style={{flexShrink: 0}} />
            <MainTabs style={{flexGrow: 1}}/>
        </Fragment>
    )
}

const MainPage = () => {
    const [spinning, setSpinning] = useState(false)

    useEffect(
        () => {
            (async () => {
                try {
                    setSpinning(true)
                    await useGlobalData.init()
                } finally {
                    setSpinning(false)
                }
            })()
        },

        []
    )

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
    }

    return (
        <Spin
            tip="加载初始数据..."
            spinning={spinning}
        >
            <div style={style}>
                <MainTabsProvider>
                    <MainPageContent />
                </MainTabsProvider>
            </div>
        </Spin>
    )
}

export default MainPage
